import { useState, useEffect, useContext } from "react";
import { app } from "@microsoft/teams-js";
import { createDirectLine } from "botframework-webchat";

import Webchat from "../../components/WebChat/Webchat";
import Tabs from "../../components/Tabs";
import TeamsContext from "../../context/TeamsContext";
import configuration from "../../configuration";
import { ErrorToaster } from "../../components/ErrorToaster/ErrorToaster";
import { tabs } from "../../utils/app.constants";
import { requestBuilderTs } from "../../utils/requestBuilder";
import { fetchSpeechServicesCredentials } from "../../utils/fetchSpeechServicesCredentials";

export const ViewerPage = () => {
  const [directLine, setDirectLine] = useState<any>();
  const [ponyfill, setPonyfill] = useState<any>();
  const [toastError, setToastError] = useState<string>("");
  const [status, setStatus] = useState<any>();
  const [selectedTabId, setSelectedTabId] = useState(1);
  const teamsContext: any = useContext(TeamsContext);
  const [currentUser, setCurrentUser] = useState<{ [key: string]: string }>({});
  const WebChat = window["WebChat" as keyof typeof window];

  useEffect(() => {
    app.getContext().then((context: any) => {
      const fullName = context?.user.userPrincipalName
        ?.split("@")[0]
        .replace(".", " ");
      setCurrentUser({
        email: context?.user.loginHint?.toLowerCase(),
        fullName: fullName,
        tid: context?.user.tenant.id,
        userid: context?.user.id,
        firstName: fullName?.split(" ")[0],
        initials:
          fullName
            ?.split(" ")
            .map((n: any) => n[0].toUpperCase())
            .join("") || "ME",
      });
    });
  }, []);


  useEffect(() => {
      localStorage.setItem("userId", currentUser?.userid);
      localStorage.setItem("userEmail", currentUser?.email);
      localStorage.setItem("fullName",currentUser?.fullName);
  }, [currentUser]);

  useEffect(() => {
    if(!currentUser?.email?.length) return;
      const conversationDataCallUrl = `${configuration.chatApiURL}/${
      currentUser?.email?.length
        ? currentUser.email
        : configuration.chatUserEmail
    }`;

    requestBuilderTs(conversationDataCallUrl, "GET", {
      [`Authorization`]: `Bearer ${teamsContext.authCode}`,
    }).then(async (conversationDataReponse) => {
      if (!!conversationDataReponse && conversationDataReponse?.value) {
        const directLineStandardCfg = {
          token: configuration.token,
          conversationId: conversationDataReponse.value.conversationId,
          streamUrl: conversationDataReponse.value.streamUrl,
          watermark: conversationDataReponse.value.watermark,
        };
        const directLine = createDirectLine({
          ...directLineStandardCfg,
          domain: "https://europe.directline.botframework.com/v3/directline"
        });

        setDirectLine(directLine);
      } else {
        const directLineStandardCfg = {
            token: configuration.token
        };
        const directLine = createDirectLine({
            ...directLineStandardCfg,
            domain: 'https://europe.directline.botframework.com/v3/directline'
        });

        setDirectLine(directLine);
      }

      const speechServiceToken = await fetchSpeechServicesCredentials();
      const ponyfill = WebChat.createCognitiveServicesSpeechServicesPonyfillFactory({
        credentials: {authorizationToken: `Bearer ${speechServiceToken}`, region: 'westeurope'},
      });
       setPonyfill(ponyfill);
    });
  }, [currentUser]);

  useEffect(() => {
    directLine?.connectionStatus$.subscribe((status: number) => {
      if (status === 5) {
        setToastError(
          "Oops, something went wrong with your assistant. Error details: Unable to connect."
        );
      }
      setStatus(status);
    });
    return () => {
      directLine?.connectionStatus$.unsubscribe();
    };
  }, [directLine]);

  useEffect(() => {
    if (status === 2 && directLine) {
      return directLine.activity$.subscribe((res: any) => {});
    } else if (status === 4) {
      return directLine?.activity$.subscribe(
        () => {},
        (error: any) =>
          setToastError(
            `Oops, something went wrong with your assistant. Error details: ${error.message}`
          )
      );
    }
  }, [status]);

  return (
    <div className="w-full h-full">
      <Tabs tabs={tabs} selected={selectedTabId} onClick={setSelectedTabId} />
      <ErrorToaster
        error={toastError}
        setError={(error: any) => setToastError(error)}
      />

      {
        <div
          style={{
            display: selectedTabId === 1 && !!directLine ? "block" : "none",
            height: "90%",
          }}
        >
          {" "}
          <Webchat {...{directLine, ponyfill}} />
        </div>
      }
    </div>
  );
};
