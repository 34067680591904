import { ITab } from "../hooks/interfaces/interfaces";

export const DIRECT_LINE_TOKEN_URL =
  "https://directline.botframework.com/v3/directline/tokens/generate";
export const SPEECH_SERVICES_TOKEN_UR = 'https://westeurope.api.cognitive.microsoft.com/sts/v1.0/issueToken';
export const MAX_NODE_LENGTH = 100;
export const IDEMPOTENT_POST_PROCESSINGS = ["reloadGraph"];
export const CORRELATION_ID = "84e3e0d4-ec31-4189-a270-46fa08b2f185";
export const TASKMATE_DEFAULT_NAME = "TaskMate Assistant";
export const tabs: ITab[] = [
  { id: 1, label: "Chat" },
];
