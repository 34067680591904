import { ReactElement, useEffect, useState } from "react";

import { app, authentication } from "@microsoft/teams-js";
import { TeamsContextProvider } from "../context/TeamsContext";

import { requestBuilderTs } from "../utils/requestBuilder";
import configuration from "../configuration";

import "../styles/global.css";

const TeamsContextProviderWrapper = (props: any): ReactElement => {
  const [state, setState] = useState({ authCode: "" });
  const [loaded, setLoaded] = useState(false);
  const [isTeams, setIsTeams] = useState(false);

  useEffect(() => {
    app
      .initialize()
      .then(() => {
        authentication.getAuthToken().then((authCode: any) => {
          const body = JSON.stringify({ token: authCode,client:"enduser" });
          const headers = {
            "Content-Type": "application/json",
            accept: "*/*",
          };
          requestBuilderTs(configuration.authUrl, "POST", headers, body)
            .then((conversationDataReponse) => {
              setState({
                ...state,
                authCode: conversationDataReponse["Token"],
              });
            })
            .then(() => {
              setIsTeams(true);
              setLoaded(true);
            });
        });
      })
      .catch(() => {
        setIsTeams(false);
        setLoaded(true);
      });
  }, []);

  return (
    <div>
      {loaded ? (
        isTeams && state.authCode ? (
          <TeamsContextProvider value={state}>
            {props.children}
          </TeamsContextProvider>
        ) : (
          <div className="accessDenied">Access Denied</div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};
export default TeamsContextProviderWrapper;
