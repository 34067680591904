import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ViewerPage } from "./pages/ViewerPage/ViewerPage";
import CommandsListPage from "./pages/CommandsListPage/CommandsListPage";

export interface IApplicationProps {}

const AppRoutes: React.FunctionComponent<IApplicationProps> = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ViewerPage />} />
        <Route path="/commands-list" element={<CommandsListPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
