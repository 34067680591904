import React from "react";

import { ITab, ITabsProps } from "../../hooks/interfaces/interfaces";

export const Tabs: React.FC<ITabsProps> = ({ selected, tabs, onClick }) => {
  
  return (
    <div className="bg-custom-tabs">
      {tabs &&
        tabs.map((tab: ITab) => (
          <div
            className={`inline-flex px-4 py-2 ml-1 rounded-t-lg border-b-2 border-transparent  hover:border-gray-300 group cursor-pointer font-bold text-lg ${
              tab.id === selected
                ? "border-custom-selected-tab  hover:border-custom-selected-tab"
                : " "
            }`}
            key={tab.id}
            onClick={() => onClick(tab.id)}
          >
            <div
              className={`${
                tab.id === selected
                  ? "text-custom-selected-tab"
                  : "text-gray-500 "
              }`}
            >
              {tab.label}
            </div>
          </div>
        ))}
    </div>
  );
};
