import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import TeamsContexProvider from "./provider/TeamsContextProvider";
import reportWebVitals from "./reportWebVitals";
import configuration from "./configuration";

const root = ReactDOM.createRoot(document.getElementById("root") as any);

root.render(
  <React.Fragment>
    {!["Local"].includes(configuration.env) ? (
      <TeamsContexProvider>
        {!!configuration.env ? <App /> : null}
      </TeamsContexProvider>
    ) : !!configuration.env ? (
      <App />
    ) : null}
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
