import React, { useState } from "react";

import { commandsWithCategories } from "./commands.list";

import "./CommandsList.css";

const CommandsList = () => {
  const [expandedItems, setExpandedItems] = useState<number[]>([]);
  
  const handleItemClick = (id: number) => {
    if (expandedItems.includes(id)) {
      let expandedItemsCopy = [...expandedItems];

      expandedItemsCopy = expandedItemsCopy.filter((item) => item !== id);
      setExpandedItems(expandedItemsCopy);
    } else {
      setExpandedItems([...expandedItems, id]);
    }
  };
  return (
    <div className="commands-list">
      {commandsWithCategories.map((command) => (
        <div key={command.id}>
          <div
            className="commands-list__command"
            onClick={() => handleItemClick(command.id)}
          >
            {command.name}
          </div>
          {
            <div>
              {command.children.map((child) => (
                <div
                  key={child.name}
                  className={
                    !expandedItems.includes(command.id)
                      ? "commands-list_children commands-list_children--closed"
                      : "commands-list_children commands-list_children--opened"
                  }
                >
                  <div className="commands-list_children-name">
                    {child.name}
                  </div>
                  <div className="commands-list_children-description">
                    {child.description}
                  </div>
                </div>
              ))}
            </div>
          }
        </div>
      ))}
    </div>
  );
};

export default React.memo(CommandsList);
