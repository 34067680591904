import React from "react";
import ReactWebChat, {
  createStyleSet,
  createStore,
} from "botframework-webchat";

import configuration from "../../configuration";
import { CORRELATION_ID,TASKMATE_DEFAULT_NAME } from "../../utils/app.constants";
import {v4 as uuidv4} from "uuid";
import "./Webchat.css";

var markdown = require("markdown-it")({ html: true, linkify: true });

const styleSet = createStyleSet({
  bubbleTextColor: "#7f7f7f",
  bubbleBackground: "#292929",
  bubbleBorderRadius: 10,
  bubbleBorderWidth: 0,
  bubbleFromUserBorderRadius: 10,
  bubbleFromUserBorderWidth: 0,
  bubbleFromUserBackground: "#7b83eb",
  bubbleFromUserTextColor: "#000000",
  bubbleImageHeight: 38,
  bubbleMinHeight: 38,
  avatarSize: 38,
  monospaceFont: "'Courier New', monospace",
  primaryFont: "'Roboto', sans-serif",
  hideUploadButton: true,
  sendBoxBackground: "#292929",
  sendBoxTextColor: "#FFFFFF",
  sendBoxBorderTop: "1px solid #000000",
  sendBoxHeight: 30,
  showSpokenText: true,
  botAvatarBackgroundColor: "#292929",
  userAvatarBackgroundColor: "#7b83eb",
  fontSizeSmall: 12,
});

styleSet.textContent = Object.assign({}, styleSet.textContent, {
  fontWeight: "500",
  fontSize: "12px",
});

const avatarOptions = {
  hideUploadButton: true,
  botAvatarInitials: "TM",
  userAvatarInitials: "Me",
  height: "24px",
  botAvatarImage:
    "https://docs.microsoft.com/en-us/azure/bot-service/v4sdk/media/logo_bot.svg?view=azure-bot-service-4.0",
};

const store = createStore(
  {},
  ({ dispatch }: any) =>
    (next: any) =>
    (action: any) => {
      if (action.type === "DIRECT_LINE/POST_ACTIVITY") {
        const graphName = {
          context: {
            graph: "knowledge",
            },
            userId:
                localStorage.getItem("userId") !== "undefined"
                    ? localStorage.getItem("userId")
                    : CORRELATION_ID,
                fullName: localStorage.getItem("fullName") !== "undefined"
                    ? localStorage.getItem("fullName")
                    : TASKMATE_DEFAULT_NAME,
                correlationId: uuidv4(),
            apptype: "user",
            from: localStorage.getItem("userEmail")
        };

        action.payload.activity.channelData = graphName;
      }

      const activityExists = !!action.payload?.activity;
        const addActivityText = !!activityExists
          ? action.payload.activity?.text
          : false;
        const indexOfSpace = addActivityText
          ? addActivityText.indexOf(" ")
          : false;

        const splittedActivity = addActivityText
          ? addActivityText.substring(indexOfSpace)
          : false;

        var expression =
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        var regex = new RegExp(expression);

        if (
          !!addActivityText &&
          addActivityText.match(regex) &&
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY"
        ) {
          const urlQP = addActivityText.split("sysparm_article=")[1];
          if (!urlQP) {
            action.payload.activity.text = `<a target="_blank" style="color: blue; cursor: pointer; font-style: italic; font-size: 16px;" href="${addActivityText}">Link</a>`;
            action.payload.activity.textFormat = "html";
          }
          else{
            action.payload.activity.text = addActivityText;
          }
        }

      return next(action);
    }
);

const Webchat = ({ directLine, ponyfill }: any) => {

  return (
    <>
      <div className="rounded w-[100%] h-[100%] min-h-[270px] p-[10px] border webchat__main">
        <div
          id="webchat"
          role="main"
          className="w-full min-w-[100px] h-[93%] webchat__main"
        >
          {directLine && (
            <ReactWebChat
              directLine={directLine}
              store={store}
              styleOptions={avatarOptions}
              styleSet={styleSet}
              username={configuration.chatUserEmail}
              renderMarkdown={markdown.render.bind(markdown)}
              webSpeechPonyfillFactory={() => ponyfill}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(Webchat);
