export const requestBuilderTs = (
  url: string,
  method: string,
  headers: { [key: string]: any },
  body?: any
) => {
  const reqObject = {
    method: method,
    headers: { ...headers },
  };

  if (body) {
    Object.assign(reqObject, { body });
  }

  return fetch(url, reqObject)
    .then((res) => res.json())
    .then((res) => res)
    .catch((err: Error) => {
      return { err: err.message };
    });
};
