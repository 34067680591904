export const commandsWithCategories = [
    {
        id: 1,
        name: "Service Now",
        children: [
            {
                name: "Find ticket",
                description: "Find ticket {ticket number} - Search for a Service Now ticket using a specific number"
            },
            {
                name: "Get info for user",
                description: "Get infor for user {userId} - Search users in Service Now database using a specific user identification"
            },
            {
                name: "Find articles",
                description: "Find articles containing {keywords} - Using a set of words search for an article which will have relevance regarding those words"
            },
            {
                name: "Resolve next ticket",
                description: "Resolve next {ticketType} ticket - Search for the upcoming ticket in a list of tickets with the same type"
            }
        ]
    },
    {
        id: 2,
        name: "Automation",
        children: [
            {
                name: "Open Webapp",
                description: "Open webapp {webappName} - Use a specific webapp name in order to navigate to its page"
            },
            {
                name: "Click",
                description: "Click {uiElemName} - Click on a specific element by using its name"
            },
            {
                name: "Fill",
                description: "Fill {text} in {uiElemName} - add text into a specific UI element"
            },
            {
                name: "Go to",
                description: "Go to {pageName} - Navigate to specific page in same domain"
            },
        ]
    },
    {
        id: 3,
        name: "Domain",
        children: [
            {
                name: "Add node",
                description: "Add {nodeName} - Inserts a new node in the existing graph"
            },
            {
                name: "Delete node",
                description: "Delete {nodeName} - Deletes a node in the existing graph"
            },
            {
                name: "Add link",
                description: "Add {linkName} between {nodeName} and {nodeName} - Connects two existing nodes"
            },
            {
                name: "Delete link",
                description: "Delete {linkName} between {nodeName} and {nodeName} - Disconnects two existing nodes"
            },
            {
                name: "Find node",
                description: "Find {nodeName} with depth {depth} - Searches for a node to put it in main layout (depth is not mandatory)"
            },
            {
                name: "Get graph",
                description: "Get graph - Reloads graph to its full state"
            },
        ]
    }
]