import React from "react";
import { useNavigate } from "react-router-dom";

import CommandsList from "../../components/CommandsList/CommandsList";
import Tabs from "../../components/Tabs";

const CommandsListPage = () => {
  const navigate = useNavigate();

  return (
    <div className="m-0 p-0 w-full h-full">
      <Tabs
        tabs={[{ id: 1, label: "Go To Main Page" }]}
        selected={0}
        onClick={() => {
          navigate("/");
        }}
      />
      <CommandsList />
    </div>
  );
};

export default React.memo(CommandsListPage);
